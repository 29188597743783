import request from "@/api/http";
/**
 * 报案
 */
export const repSub = (data) => {
  return request({
    url: "/api/system/rep/sub",
    method: "post",
    data
  });
};

export const collect = (data) => {
  return request({
    url: "/zhongkui/feedback/collect",
    method: "post",
    data
  });
};

/**
 * 查看
 */
export const getRepList = (pageNum) => {
  return request({
    url: "/api/system/rep/view",
    method: "post",
    params:{
      pageNum
    }
  });
};
/**
 * 报案
 */
export const getCodeByChain = (id) => {
  return request({
    url: "/api/system/rep/del",
    method: "post",
    data:{
      id
    }
  });
};
